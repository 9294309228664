<template>
  <div class="campus">
    <div class="container">
      <div class="campus__header">
        <a :href="`/app/profile?id=${this.userId}`" class="btn-back">
          <SvgArrowLeft />
        </a>

        <div class="campus__name">{{ fullName }}</div>
        <Avatar class="avatar" :avatar="mainAvatar.image" :initials="mainAvatar.initials" />
      </div>

      <p class="text--sm font-medium my-8">
        <span>{{ usersCount | normalizeCount }}</span>
        <span class="text--sm ml-1">{{ $t('subscribers') }}</span>
      </p>

      <div class="users">
        <CardCampusParticipant
          class="card-campus"
          v-for="user in users.items"
          :item="user"
          :key="user.id"
          :current-user="currentUser"
        />
        <Observer class="dummy" @intersect="handleIntersect"></Observer>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '@/api/UserService';
import Avatar from '../components/UI/Avatar.vue';
import { Vue } from 'vue-property-decorator';
import Observer from '../components/Observer.vue';
import CardCampusParticipant from '../components/CardCampusParticipant.vue';

export default {
  components: { Avatar, Observer, CardCampusParticipant },
  data() {
    return {
      user: {},
      currentUser: {},
      users: {
        items: [],
        pagination: {},
      },
    };
  },

  created() {
    this.getUser();
    this.getCurrentUser();
    this.getUsers();
  },

  computed: {
    userId() {
      let params = new URL(document.location).searchParams;
      let userId = params.get('id');

      return userId;
    },

    fullName() {
      return this.user?.firstName + ' ' + this.user?.lastName;
    },

    usersCount() {
      return this.users?.pagination?.total;
    },

    mainAvatar() {
      const { name = '' } = this.user;
      const [fi = '', la = ''] = name.split(' ');
      const initials = fi.slice(0, 1) + la.slice(0, 1);
      const { image } = this.user;

      return { initials, image };
    },
  },

  methods: {
    async getUser() {
      const { data } = await UserService.getForeignUserProfile(this.userId);
      this.user = data;
    },

    async getCurrentUser() {
      await UserService.getUserProfile().then((response) => {
        this.currentUser = response.data;
      });
    },

    async getUsers(nextPage = 1) {
      const { data } = await UserService.getForeignUserFollowers(this.userId, nextPage);
      const { pagination, items } = data;
      const newItems = this.users.items.concat(items);

      Vue.set(this.users, 'items', newItems);
      Vue.set(this.users, 'pagination', pagination);
    },

    async handleIntersect() {
      const { lastPage, currentPage } = this.users.pagination;

      if (lastPage > currentPage) {
        let nextPage = (this.users.pagination.currentPage += 1);
        this.getUsers(nextPage);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./CampusParticipants/style.scss"></style>
